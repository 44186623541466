<template>
	<div class="">
		<h1 class="title-search">{{$t("titles.admin")}}</h1>
		<div id="onglets" class="tab-widget desktop">
			<div class="tab-widget-buttons-wrapper">
				<component v-for="route in routes" :key="route.path" :is="route.disable ? 'div' : 'router-link'" class="btn tab-widget-button" :class="{ disabled: route.disable }" :data-admin-route="route.name" :to="{ name: route.name }">
					<span :class="route.disable ? '' : 'anim-underline'">{{ route.title }}</span>
				</component>
				<div class="tab-widget-button-spacer"></div>
			</div>
		</div>
		<div v-if="$store.state.domain == 'esthetics'" id="block-tabs" class="mobile slider carousel slide2" data-index="0" data-interval="0" :data-max-index="routes.length">
			<div class="carousel-inner">
				<component v-for="route in routes" :key="route.path" :is="route.disable ? 'div' : 'router-link'" :class="{ disabled: route.disable }" class="btn tab-widget-button carousel-item" :to="{ name: route.name }">
					<span :class="route.disable ? '' : 'anim-underline'">{{ route.title_mobile || route.title }}</span>
				</component>
			</div>
			<div class="carousel-control-prev" data-slide="prev" data-targets="#block-tabs">
				<div></div>
			</div>
			<div class="carousel-control-next" data-slide="next" data-targets="#block-tabs">
				<div></div>
			</div>
		</div>
		<!--Carousel v-else-if="$store.state.domain == 'coachs'" :per-page="3" :scrollPerPage="false" :paginationEnabled="false" :resistanceCoef="0">
			<Slide v-for="route in routes" :key="route.path" class="admin-tab">
				<component :is="route.disable ? 'div' : 'div'" :class="{ disabled: route.disable }" class="btn tab-widget-button carousel-item" :to="{ name: route.name }">
					<span :class="route.disable ? '' : 'anim-underline'">{{ route.title_mobile || route.title }}</span>
				</component>
			</Slide>
			<Slide class="admin-tab">
			</Slide>
		</Carousel-->
		<div ref="carouscroll" class="carouscroll mobile">
			<div class="admin-tab"/>
			<div v-for="route in routes" :key="route.path" class="admin-tab" :data-to="route.name" @click="carouscrollClicked(route.name)">
				<component :is="route.disable ? 'div' : 'router-link'" :class="{ disabled: route.disable }" class="btn tab-widget-button carousel-item" :to="{ name: route.name }" @click.native.prevent>
					<span :class="route.disable ? '' : 'anim-underline'">{{ route.title_mobile || route.title }}</span>
				</component>
				<!--div :class="{ disabled: route.disable }" class="btn tab-widget-button carousel-item">
					<span :class="route.disable ? '' : 'anim-underline'">{{ route.title_mobile || route.title }}</span>
				</div-->
			</div>
			<div class="admin-tab"/>
		</div>
		<router-view></router-view>
	</div>
</template>

<script>
	import carousel from '../components/carousel.js'
	import { Carousel, Slide } from 'vue-carousel'

	export default {
		components: {
			Carousel,
			Slide
		},
		data() {
			return {
				tabsScrollTimer: null
			}
		},
		computed: {
			routes() {
				return [
					{ name: 'services', title: this.$t("common.services") },
					// { name: 'sorting', title: this.$t("common.ordserv"), hide: !this.$store.getters.salonPrestationsHavePauses() },
					// { name: 'formulas', title: this.$t("common.pack") },
					// { name: 'technic', title: this.$t("data.servicestype[-1]"), hide: this.$store.state.domain == "coachs" },
					{ name: 'staff', title: this.$t("administration.team") },
					//{ name: 'vacations', title: this.$t("common.holiday"), disable: !!process.env.VUE_APP_DEMO },
					// { name: 'messages', title: this.$t("common.sms"), title_mobile: this.$t("common.ad"), disable: !!process.env.VUE_APP_DEMO },
					// { name: 'bills', title: this.$t("common.bills")/*, hide: this.$store.state.salon.stripeid == "free"*/, disable: !!process.env.VUE_APP_DEMO },
					{ name: 'website', title: this.$t("common.website") },
				].filter(route => !route.hide)
			}
		},
		watch: {
			// This functions makes mobile-carousel follow desktop-tabs
			// It detects route changes, and programmatically moves itself to the corresponding tab
			'$route'( route ) {
				if ( route.path.search( "/administration" ) === 0 ) {
					if ( this.$store.state.domain == "esthetics" ) {
						this.moveMobileCarousel( route )
					} else {
					}
				}
				if ( route.path === "/administration" ) {
				//	this.$router.replace( "/administration/services" )
				}
			}
		},
		mounted() {
			if ( this.$store.state.domain != "esthetics" ) {
				this.$refs.carouscroll.addEventListener( "scroll", ev => {
					if ( this.tabsScrollTimer ) {
						window.clearTimeout( this.tabsScrollTimer )
						this.tabsScrollTimer = null
					}

					const center = ( document.innerWidth || window.innerWidth ) / 2
					const nodes = Array.from(ev.target.childNodes)
					let newRoute = null
					let newNode = null
					nodes.forEach( node => {
						if ( node.getBoundingClientRect().left < center && node.getBoundingClientRect().right > center ) {
							node.classList.add( "centered" )
							newRoute = "/administration/" + node.getAttribute( "data-to" )
							newNode = node
						} else {
							node.classList.remove( "centered" )
						}
					})

					this.tabsScrollTimer = window.setTimeout( () => {
						if ( newRoute && newNode ) {
							if ( this.$route.path != newRoute ) {
								this.$router.replace( newRoute )
							}
						}
					}, 150 )
				})
				return
			}
			var thiz = this
			carousel.install( this )

			$(this.$el).find( '.carousel-control-prev' ).click( function() {
				$(thiz.$el).find( '.carousel-inner' ).children().find( ".anim-underline" ).removeClass( "hover" )
				$(thiz.$el).find( '.carousel-inner' ).children()[1].dispatchEvent( new Event( "click" ) )
			})
			$(this.$el).find( '.carousel-control-prev' ).hover( function() {
				$( $(thiz.$el).find( '.carousel-inner' ).children()[0] ).find( ".anim-underline" ).addClass( "hover" )
			}, function() {
				$( $(thiz.$el).find( '.carousel-inner' ).children()[0] ).find( ".anim-underline" ).removeClass( "hover" )
			})

			$(this.$el).find( '.carousel-control-next' ).click( function() {
				$(thiz.$el).find( '.carousel-inner' ).children().find( ".anim-underline" ).removeClass( "hover" )
				$(thiz.$el).find( '.carousel-inner' ).children()[2].dispatchEvent( new Event( "click" ) )
			})
			$(this.$el).find( '.carousel-control-next' ).hover( function() {
				$( $(thiz.$el).find( '.carousel-inner' ).children()[2] ).find( ".anim-underline" ).addClass( "hover" )
			}, function() {
				$( $(thiz.$el).find( '.carousel-inner' ).children()[2] ).find( ".anim-underline" ).removeClass( "hover" )
			})

			this.moveMobileCarousel( this.$route, true )
		},
		methods: {
			carouscrollClicked( route ) {
				Array.from(this.$refs.carouscroll.childNodes).forEach( node => {
					if ( node.getAttribute( "data-to" ) == route ) {
						node.classList.add( "centered" )
						node.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
					} else {
						node.classList.remove( "centered" )
					}
				})
			},
			moveMobileCarousel( route, force ) {
				if ( window.innerWidth >= 768 || force || route.path === "/administration" /* first show */ ) {
					let exists = false
					let realRoute = null
					for ( let i = 0; i < this.routes.length; i++ ) {
						if ( this.routes[i].name === "services" && !route.name && route.path.indexOf( "/administration" ) == 0 ) {
							realRoute = { path: "/administration/services" }
							exists = true
							break
						}
						if ( this.routes[i].name === route.name ) {
							realRoute = route
							exists = true
							break
						}
					}
					// Don't do anything if current path has no corresponding route (probably a typo from user)
					if ( exists ) {
						let overflow = 0
						let inner = $(this.$el).find( '.carousel-inner' )
						while ( inner.children()[1].getAttribute( "href" ) !== "#" + realRoute.path && overflow <= this.routes.length ) {
							let first = $( inner.children()[0] )
							first.detach()
							inner.append( first )
							overflow++
						}
					}
				}
			}
		}
	}
</script>

<style scoped lang="scss" src="../css/pages/administration.scss"/>
